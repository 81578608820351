<!-- adultSummary   老师查看学生活动详情-->
<template>
  <div style="height: 100%">
    <div style="height: 50px">
      <el-button type="primary" icon="el-icon-arrow-left" @click="go_back">返回</el-button>
    </div>
    <el-card class="box-card">
      <div class="content">
        <p>学生基本情况表</p>
        <div class="table">
          <el-scrollbar style="height: 100%">
            <el-table
              :data="tableData"
              style="width: 100%"
              :header-cell-style="{
                background: '#F3F7FF',
                color: '#0B1B31',
                fontSize: '14px',
                fontWeight: '500',
              }"
            >
              <el-table-column prop="date" label="变式编号" width="180"></el-table-column>
              <el-table-column prop="date" label="学生用户名"></el-table-column>
              <el-table-column prop="date" label="学生姓名"></el-table-column>
              <el-table-column prop="date" label="变式练习次数"></el-table-column>
              <el-table-column prop="date" label="最后一次练习时间"></el-table-column>
              <el-table-column prop="name" label="正确率"></el-table-column>
              <el-table-column prop="address" label="内化率"></el-table-column>
            </el-table>
          </el-scrollbar>
        </div>
        <el-pagination
          @size-change="(val) => handleSizeChange(val, 'studentSize')"
          @current-change="(val) => handleCurrentChange(val, 'studentPage')"
          :current-page="page.studentPage"
          :page-sizes="[5, 10, 20, 50]"
          :page-size="page.studentPage"
          layout="total, sizes, prev, pager, next, jumper"
          :total="page.studentTotal"
        ></el-pagination>
      </div>
    </el-card>
  </div>
</template>

<script>
export default {
  data () {
    return {
      page: {
        studentPage: 1,
        studentSize: 5,
        studentTotal: 400,
        variantPage: 5,
        variantSize: 5,
        variantTotal: 400,
      },
      tableData: [],
    };
  },

  components: {},

  methods: {
    go_back () {
      this.$router.go(-1); //返回上一层
    },
    //每页
    handleSizeChange (val, name) {
      this.page[name] = val;
    },
    //当前页
    handleCurrentChange (val, name) {
      this.page[name] = val;
    },
  },
};
</script>
<style lang='less' scoped>
@import './style/index.less';
</style>
